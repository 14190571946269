// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"
import Head from "../components/head"

const Residents = (props: PageProps) => (
  <Layout color="primary">
    <SEO title="Règles de confidentialité pour l’application Syndic +" />
    <Head color="primary" title = "Règles de confidentialité pour l’application Syndic + ">
    </Head>
    <Content>
      <p>La présente politique de confidentialité s’applique aux utilisateurs des appareils Android.</p>
      <p>En naviguant sur l’application Syndic +, vous pouvez partager des informations. Ces informations nous permettent d’améliorer nos services, afin de mieux répondre à vos attentes, et de rendre l’application plus performante et pertinente. Il est important qu’en tant qu’utilisateur de notre application, vous compreniez comment sont utilisées les données que vous fournissez.</p>
    </Content>
    <Content>
      <h1>Données collectées</h1>
      <p>Les informations collectées par le biais de l’application visent à améliorer cette dernière. Nous collectons des informations relatives aux services que vous utilisez et à l’usage que vous en faites.</p>
      <p>Il s’agit de données de base, telles les « cliques » que vous faites sur chaque lien de l’application, afin que nous puissions évaluer la pertinence de chacune de nos interfaces. </p>
      <p>Mais également des données plus complexes :  </p>
      <p>
        <ul>
          <li>relatives à l’appareil utilisé : le modèle, la version du système d’exploitation, les identifiants uniques de l’appareil et les informations relatives au réseau mobile </li>
          <li>relatives aux données de localisation : nous pouvons collecter et traiter des données relatives à votre position </li>
        </ul>
      </p>
    </Content>
    <Content>
      <h1>Utilisation des données</h1>        
      <p>Votre nom et prénom sont utilisés pour identifier le contenu que vous partager au sein de votre immeuble. </p>
      <p>Votre email et votre numéro de téléphone sont uniquement accessibles par votre syndic.</p>
      <p>La collecte de ces informations nous permet de gérer, protéger et d’améliorer nos services. Cela nous permet de vous proposer des contenus adaptés et de vous transmettre les informations qui vous intéressent.</p>
    </Content>
    <Content>
      <h1>Utilisation de la caméra</h1>
      <p>La caméra de votre appareil mobile est uniquement utilisé afin de prendre une photo pour faciliter l’ajout d’image.</p>
    </Content>
    <Content>
      <h1>Divulgation à des tiers</h1>
      <p>Aucune donnée n’est partagée par Syndic +.</p>
    </Content>
    <Content>
      <h1>Champ d’application des présentes Règles de confidentialité</h1>
      <p>Ces règles s’appliquent à tous les services proposés par l’application Android Syndic +.</p>
    </Content>
    <Content>
        <h1>Modifications</h1>
        <p>Les présentes Règles de confidentialité peuvent être amenées à évoluer. Le cas échéant, nous publierons toute modification sur cette page, afin que vous soyez informés de telles évolutions.</p>
    </Content>
  </Layout>
)

export default Residents
